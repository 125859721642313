import * as React from 'react';
import { Fragment } from 'react';
import {
    Stack,
    HStack,
    Avatar,
    Button,
    Image,
    Wrap,
    WrapItem,
    Box,
} from '@chakra-ui/react';
import AvatarMessage from '../../atoms/AvatarMessage/AvatarMessage';
import UserMessage from '../../atoms/UserMessage/UserMessage';
import { getChatStyles } from '../../theme/consts';
import { useInteractionSettingsStore } from '../../stores';

interface Props {
    avatarImageSrc: string;
    isTextMode: boolean;
    onCitationClick: (citation: Citation) => void;
    messages: TextMessage[];
    innerContainerRef: React.RefObject<HTMLDivElement>;
}

const ChatHistory: React.FC<Props> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    messages,
    innerContainerRef,
}) => {
    const setAttachment = useInteractionSettingsStore(
        state => state.setAttachment,
    );
    const openModal = useInteractionSettingsStore(state => state.openModal);
    const styles = getChatStyles(isTextMode);

    return (
        <Fragment>
            <Stack
                {...styles.historyContainer}
                mx='auto'
                ref={innerContainerRef}
            >
                {messages.map(message => (
                    <Fragment key={message.actionId}>
                        {message.type === 'user' ? (
                            <HStack justifyContent='flex-end'>
                                <UserMessage
                                    text={message.text}
                                    isTextMode={isTextMode}
                                />
                            </HStack>
                        ) : (
                            <Fragment>
                                <HStack alignItems='flex-start'>
                                    {isTextMode && (
                                        <Avatar
                                            src={avatarImageSrc}
                                            boxSize='32px'
                                        />
                                    )}
                                    <Box>
                                        <AvatarMessage
                                            text={message.text}
                                            onCitationClick={onCitationClick}
                                            isTextMode={isTextMode}
                                        />
                                        {message.attachments && (
                                            <Wrap
                                                spacing={4}
                                                mb='10px!important'
                                            >
                                                {message.attachments.map(
                                                    (attachment, i) => (
                                                        <WrapItem key={i}>
                                                            <Button
                                                                variant='unstyled'
                                                                p='0px'
                                                                w='125px'
                                                                h='125px'
                                                                onClick={() => {
                                                                    openModal();
                                                                    setAttachment(
                                                                        {
                                                                            title:
                                                                                attachment.name,
                                                                            description:
                                                                                attachment.description,
                                                                            url:
                                                                                attachment.url,
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                <Image
                                                                    src={
                                                                        attachment.url
                                                                    }
                                                                    alt={
                                                                        attachment.name
                                                                    }
                                                                    objectFit='cover'
                                                                    w='125px'
                                                                    h='125px'
                                                                    borderRadius='10px'
                                                                />
                                                            </Button>
                                                        </WrapItem>
                                                    ),
                                                )}
                                            </Wrap>
                                        )}
                                    </Box>
                                </HStack>
                            </Fragment>
                        )}
                    </Fragment>
                ))}
            </Stack>
        </Fragment>
    );
};

export default ChatHistory;
