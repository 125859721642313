import * as React from 'react';
import { Box, Center, Grid, GridItem } from '@chakra-ui/react';
import ControlToggle from '../../molecules/ControlToggle/ControlToggle';
import TalkButton from '../../molecules/TalkButton/TalkButton';
import { useInteractionSetting } from '../../stores/';
import {
    InteractionContextAPI,
    InteractionContextData,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { getLastAgentMessage } from '../../utils/interaction/utils';
import { Props as ChatProps } from '../../organisms/Chat/Chat';
import AvatarMessage from '../../atoms/AvatarMessage/AvatarMessage';
import { AvatarText } from '../../../../core/src/types';

export default function AvatarMobileTemplate({ onCitationClick }: ChatProps) {
    const {
        isCaptionsOn,
        isAudioOn,
        toggleAudio,
        isMicOn,
        toggleMic,
    } = useInteractionSetting([
        'isCaptionsOn',
        'isAudioOn',
        'toggleAudio',
        'isMicOn',
        'toggleMic',
    ]);

    const { messages } = useInteractionContext(InteractionContextData);
    const lastAgentMessage = getLastAgentMessage(messages);

    const { interrupt } = useInteractionContext(InteractionContextAPI);
    const { isAgentBusy } = useInteractionContext(InteractionContextStatus);

    return (
        <Grid
            h='100%'
            w='100%'
            templateColumns='repeat(3, 1fr)'
            templateRows='repeat(7, 1fr)'
            position='fixed'
            bottom='0px'
            pb='20px'
            zIndex={10}
        >
            <GridItem colSpan={3} rowSpan={5} /> {/* Empty slot */}
            <GridItem colSpan={3}>
                <Center h='100%'>
                    <Box w={['90%', '60%', '60%', '50%']}>
                        {isCaptionsOn && lastAgentMessage && (
                            <AvatarMessage
                                text={lastAgentMessage.text as AvatarText}
                                isTextMode={false}
                                onCitationClick={onCitationClick}
                            />
                        )}
                    </Box>
                </Center>
            </GridItem>
            <GridItem /> {/* Empty slot */}
            <GridItem>
                <Center h='100%' alignItems='flex-end'>
                    <TalkButton
                        state={
                            isMicOn
                                ? 'speak'
                                : isAgentBusy
                                ? 'interrupt'
                                : 'idle'
                        }
                        toggleMic={toggleMic}
                        interrupt={interrupt}
                    />
                </Center>
            </GridItem>
            <GridItem>
                <Center h='100%' alignItems='flex-end'>
                    <ControlToggle
                        icon={isAudioOn ? 'audioOn' : 'audioOff'}
                        ariaLabel='Audio toggle button'
                        tooltipLabel={
                            isAudioOn ? 'Turn audio off' : 'Turn audio on'
                        }
                        onClick={() => toggleAudio()}
                    />
                </Center>
            </GridItem>
        </Grid>
    );
}
