import * as React from 'react';
import { useEffect, useRef } from 'react';

export default function useChatScroll(
    messages: TextMessage[],
    containerRef: React.RefObject<HTMLDivElement>,
    innerContainerRef: React.RefObject<HTMLDivElement>,
) {
    const container = containerRef.current;
    const innerContainer = innerContainerRef.current;
    const scrolledToBottomAtStart = useRef(false);
    const prevScrollTop = useRef(0);
    const isScrollMode = useRef(false);

    useEffect(() => {
        const container = containerRef.current;

        if (container && !isScrollMode.current) {
            container.scrollTop = container.scrollHeight + 10;
            scrolledToBottomAtStart.current = true;
        }
    }, [messages.length, containerRef]);

    useEffect(() => {
        if (container && innerContainer) {
            const resizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    if (entry.contentBoxSize) {
                        if (container) {
                            container.lastElementChild?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'end',
                            });
                        }
                    }
                }
            });

            const handleScroll = (e: Event) => {
                const target = e.target as HTMLDivElement;
                const currentScrollTop = target.scrollTop;
                const scrollHeight = target.scrollHeight;
                const clientHeight = target.clientHeight;

                const isBottom =
                    Math.abs(scrollHeight - (currentScrollTop + clientHeight)) <
                    2;

                if (innerContainer) {
                    if (
                        currentScrollTop < prevScrollTop.current &&
                        // when avatar is enabled and user scrolls to bottom, sometimes the container automatically scrolls a tiny bit up. This condition prevents .unbserve() being called when that happens
                        prevScrollTop.current - currentScrollTop > 2 &&
                        isScrollMode.current
                    ) {
                        resizeObserver.unobserve(innerContainer);
                        isScrollMode.current = false;
                    } else if (isBottom && !isScrollMode.current) {
                        resizeObserver.observe(innerContainer);
                        isScrollMode.current = true;
                    }
                }

                prevScrollTop.current = currentScrollTop;
            };

            resizeObserver.observe(innerContainer);
            isScrollMode.current = true;
            container.addEventListener('scroll', handleScroll);

            return () => {
                resizeObserver.disconnect();
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [container, innerContainer]);
}
