import {
    ActiveStage,
    AgentAction,
    UserMessageMeta,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import useQueue from '../../../../core/src/hooks/useQueue';
import useTypewriterEffect from '../../../../core/src/hooks/useTypewriterEffect';
import {
    ChatConnection,
    ChatConnOptions,
    ChatWebsocketCloseReason,
    ChatWebsocketCodes,
    openChatHttpConn,
    openChatWebsocketConn,
} from './InteractionConnection';
import { parseChatEntries } from './utils';
import { Session } from '../../../../../apps/mooc-frontend/src/components/activities/ActivityContent';
import ExerciseAPI from '../../../../../apps/mooc-frontend/src/components/activities/ExerciseAPI';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';
import { InteractionActions, useInteractionAgent } from './useInteractionAgent';
import { AvatarText } from '../../../../core/src/types';

const hintsData = [
    'What is prostate cancer?',
    'What was the primary result?',
    'What does the study mean for patients?',
    'Describe Figure 2',
];

interface RunnableAction {
    // Resolves when an action has finished processing
    promise: Promise<any>;
    // Will immediately terminate a running action
    stop: () => void;
}

export type ActionProcessor = (
    action: AgentAction,
    activeStage: ActiveStage,
) => RunnableAction | undefined;

export interface InitProps {
    session: Session;
    exerciseAPI: ExerciseAPI;
    useStreaming: boolean;
    messageDisplayLimit?: number;
}

const useInteraction = ({
    session,
    exerciseAPI,
    useStreaming,
    messageDisplayLimit,
}: InitProps) => {
    const sessionId = session.id;
    const stageId = session.active_stage.id;
    const activeStage = session.active_stage;

    const chatUrl = `sessions/${sessionId}/stages/${stageId}/`;

    const [messages, setMessages, addMessages, clearMessages] = useQueue<
        TextMessage
    >(messageDisplayLimit);

    const [hints, setHints] = useState(hintsData);

    // Do not use spead operator as it will recreate the object unecessarily causing performance issues downstream
    const {
        act,
        status,
        isDisabled,
        isAgentBusy,
        awaitingResponse,
    } = useInteractionAgent();

    const { displayTextual, stop: stopTextual } = useTypewriterEffect(
        40,
        setMessages,
    );

    const chatConnectionRef = useRef<ChatConnection | null>(null);
    const avatarRef = useRef(null);
    const runningActions = useRef(new Map<any, RunnableAction[]>());
    const interruptedActions = useRef(new Set<any>());

    const actionProcessors = useRef<Map<string, ActionProcessor>>(new Map());
    const addActionProcessor = useCallback(
        (name: string, processor: ActionProcessor) => {
            if (actionProcessors.current.has(name)) {
                console.warn(`Overriding action processor ${name}`);
            }
            actionProcessors.current.set(name, processor);
        },
        [],
    );
    const removeActionProcessor = useCallback((name: string) => {
        actionProcessors.current.delete(name);
    }, []);

    const runAction: (
        action: AgentAction,
        activeStage: ActiveStage,
    ) => RunnableAction = useCallback((action, activeStage) => {
        const { promise, resolve } = promiseWithResolvers();

        const agentActionTasks: RunnableAction['promise'][] = [];
        const stopFunctions: RunnableAction['stop'][] = [];

        const stop = () => stopFunctions.forEach(f => f());

        actionProcessors.current.forEach(processor => {
            const processorOutcome = processor(action, activeStage);
            if (processorOutcome) {
                const { promise, stop } = processorOutcome;
                agentActionTasks.push(promise);
                stopFunctions.push(stop);
            }
        });

        Promise.all(agentActionTasks).then(() => resolve());
        return { promise, stop };
    }, []);

    useEffect(() => {
        const textProcessor: ActionProcessor | undefined = action => {
            const { textual, chunks } = action.payload;
            if (!textual?.text) return;

            const { promise, resolve } = promiseWithResolvers();

            let displayText: AvatarText = [];
            if (chunks && chunks.length) {
                chunks.forEach(({ text, citations }) => {
                    const endsWithPunctuation = /[.!?]$/.test(text);
                    const punctuation = endsWithPunctuation
                        ? text[text.length - 1]
                        : '';
                    const mainText = endsWithPunctuation
                        ? text.slice(0, -1)
                        : text;
                    displayText.push(
                        ...mainText.split(''),
                        ...citations,
                        punctuation,
                    );
                });
            } else {
                displayText = textual.text.split('');
            }

            displayTextual(
                displayText,
                action.id,
                action.partial,
                !!textual.hidden,
                action.payload.media?.attachments,
                () => resolve(),
            );

            const stop = () => stopTextual(action.id);

            return {
                promise,
                stop,
            };
        };
        addActionProcessor('text', textProcessor);

        return () => {
            removeActionProcessor('text');
        };
    }, [
        addActionProcessor,
        displayTextual,
        removeActionProcessor,
        stopTextual,
    ]);

    const completeAction = useCallback(
        (id: any) => {
            const promises = runningActions.current
                .get(id)
                ?.map(runnable => runnable.promise);

            if (promises) {
                Promise.all(promises).finally(() => {
                    act(InteractionActions.processed);
                    runningActions.current.delete(id);
                });
            }
        },
        [act],
    );

    useEffect(() => {
        act(InteractionActions.initialise);
    }, [act]);

    useEffect(() => {
        let shouldStillUseConnection = true;
        const chatMessages = parseChatEntries(activeStage.entries);
        setMessages(chatMessages);

        const connOptions: ChatConnOptions = {
            chatUrl,
            activeStage: activeStage,
            act,
            sessionData: session,
            avatarRef,
            exerciseAPI: exerciseAPI,
            setActions: actions => {
                actions.forEach(action => {
                    if (interruptedActions.current.has(action.id)) {
                        return;
                    }

                    const { payload } = action;
                    const isFinal = !action.partial;
                    const hasFinishedActions = !!payload.control
                        ?.finished_actions.length;
                    const isProcessingRequired = payload.textual?.text;

                    // The below handles an edge case to this, if we don't receive any
                    // speak action, and want to early exit the processing state, usually
                    // at the start of interactions, the below conditions will take care of it
                    if (
                        !isProcessingRequired &&
                        !hasFinishedActions &&
                        isFinal
                    ) {
                        act(InteractionActions.processed);
                        return;
                    }

                    if (payload.control?.finished_actions.length) {
                        payload.control.finished_actions.forEach(
                            completeAction,
                        );
                        return;
                    }

                    if (action.id) {
                        act(InteractionActions.process);
                        if (!runningActions.current.has(action.id)) {
                            runningActions.current.set(action.id, []);
                        }

                        const actionParts = runningActions.current.get(
                            action.id,
                        )!;
                        const runningAction = runAction(action, activeStage);
                        actionParts.push(runningAction);

                        if (isFinal) {
                            completeAction(action.id);
                        }
                    }
                });
            },
            onSuccess: connection => {
                if (shouldStillUseConnection) {
                    chatConnectionRef.current = connection;
                } else {
                    connection.close(
                        ChatWebsocketCodes.CLOSE_NORMAL,
                        // escape hatch to prevent the state from updating to 'disconnected'
                        // because the new connection will have been initialised by that point
                        ChatWebsocketCloseReason.CLIENT_RECONNECT,
                    );
                    act(InteractionActions.disconnect);
                }
            },
            // Skips unnecessary reconnects
            shouldReconnect: () => shouldStillUseConnection,
            onError: console.log,
        };

        const openConn = useStreaming
            ? openChatWebsocketConn
            : openChatHttpConn;

        openConn(connOptions);

        return () => {
            shouldStillUseConnection = false;
            chatConnectionRef.current?.close(
                ChatWebsocketCodes.CLOSE_NORMAL,
                ChatWebsocketCloseReason.CLIENT_RECONNECT,
            );
            act(InteractionActions.disconnect);
        };
    }, [
        runAction,
        chatUrl,
        exerciseAPI,
        setMessages,
        useStreaming,
        activeStage,
        session,
        act,
        completeAction,
    ]);

    const interrupt = useCallback(() => {
        Array.from(runningActions.current.entries()).forEach(
            ([id, runningActions]) => {
                interruptedActions.current.add(id);
                runningActions.forEach(ra => ra.stop());
            },
        );
    }, []);

    const sendMessage = useCallback(
        (message: string, meta: UserMessageMeta) => {
            interrupt();

            addMessages({
                type: 'user',
                text: message,
                actionId: new Date().getTime(),
            });

            const requestData = {
                action_type: 'utterance',
                payload: {
                    text: message,
                    meta,
                },
                skip_tts_synthesis: true,
            };
            chatConnectionRef.current?.send(JSON.stringify(requestData));
            act(InteractionActions.send);
        },
        [act, addMessages, interrupt],
    );

    return {
        hints,
        messages,
        activeStage,
        interrupt,
        sendMessage,
        agentState: status,
        isAgentBusy,
        isDisabled,
        awaitingResponse,
        addActionProcessor,
        removeActionProcessor,
    };
};
export default useInteraction;
